@use 'sass:math';

.p-chips {
    .p-chips-multiple-container {
        padding: math.div(nth($inputPadding, 1), 2) nth($inputPadding, 2);
        gap: $inlineSpacing;

        &:not(.p-disabled):hover {
            border-color: $inputHoverBorderColor;
        }

        &:not(.p-disabled).p-focus {
            @include focused-input();
        }

        .p-chips-token {
            padding: math.div(nth($inputPadding, 1), 2) nth($inputPadding, 2);
            margin-right: $inlineSpacing;
            background: $highlightBg;
            color: $chipTextColor;
            border-radius: $chipBorderRadius;

            .p-chips-token-icon {
                margin-left: $inlineSpacing;
            }
        }

        .p-chips-input-token {
            padding: math.div(nth($inputPadding, 1), 2) 0;
                
            input {
                font-family: var(--font-family);
                font-feature-settings: var(--font-feature-settings, normal);
                font-size: $inputTextFontSize;
                color: $textColor;
                padding: 0;
                margin: 0;
            }
        }
    }

    &.p-invalid.p-component > .p-inputtext {
        @include invalid-input();
    }
}
