$primaryColor: #002D57 !default;
$primaryColorAlt: #002D57 !default;
$primaryLightColor: #C0D0E0 !default;
$primaryDarkColor: scale-color($primaryColor, $lightness: -10%) !default;
$primaryDarkerColor: scale-color($primaryColor, $lightness: -20%) !default;
$primaryTextColor: #ffffff !default;

$colors: (
    "blue": #5E81AC,
    "green": #7FA366,
    "yellow": #DAC57A,
    "cyan": #71ABC2,
    "pink": #BF6185,
    "indigo": #4E6AA4,
    "teal": #5EAC90,
    "orange": #D08770,
    "bluegray": #7E8D9F,
    "purple": #9A6796,
    "red": #BF616A,
    "primary": $primaryColor
) !default;

$infoColor: #ffffff !default;
$infoLightColor: #90d6e9 !default;
$successColor: #7FA366 !default;
$successLightColor: #87e435 !default;
$warningColor: #D08770 !default;
$warningLightColor: #e2c194 !default;
$helpColor: #9A6796 !default;
$helpLightColor: #B48EAD !default;
$dangerColor: #BF616A !default;
$dangerLightColor: #D88889 !default;
$yellowColor: #002d5778 !default;

$black50: #707888 !default;
$black100: #4C566A !default;
$black200: #434C5E !default;
$black300: #3B4252 !default;
$black400: #2E3440 !default;

$white: #ffffff !default;
$white100: #ECEFF4 !default;
$white200: #E5E9F0 !default;
$white300: #D8DEE9 !default;

//global
$fontFamily:'Inter', sans-serif !default;
$fontSize:1rem !default;
$fontWeight:normal !default;
$textColor:$black100 !default;
$textSecondaryColor:$primaryColorAlt !default;
$borderRadius:4px !default;
$transitionDuration:.2s !default;
$formElementTransition:background-color $transitionDuration, color $transitionDuration, border-color $transitionDuration, box-shadow $transitionDuration !default;
$actionIconTransition:background-color $transitionDuration, color $transitionDuration, box-shadow $transitionDuration !default;
$listItemTransition: none !default;
$primeIconFontSize:1rem !default;
$divider:1px solid $white200 !default;
$inlineSpacing:.5rem !default;
$disabledOpacity:.6 !default;
$maskBg:rgba(112, 120, 136, .5) !default;
$loadingIconFontSize:2rem !default;
$errorColor:#BF616A !default;

//highlight
$highlightBg: $white300 !default;
$highlightTextColor: $black400 !default;

//scale
$scaleSM:0.875 !default;
$scaleLG:1.25 !default;

//focus
$focusOutlineColor:$primaryLightColor !default;
$focusOutline:0 none !default;
$focusOutlineOffset:0 !default;
$focusShadow:0 0 0 0.2rem $focusOutlineColor !default;

//action icons
$actionIconWidth:2rem !default;
$actionIconHeight:2rem !default;
$actionIconBg:transparent !default;
$actionIconBorder:2px solid transparent !default;
$actionIconColor:$primaryColorAlt !default;
$actionIconHoverBg:$white !default;
$actionIconHoverBorderColor:$primaryColor !default;
$actionIconHoverColor:$black100 !default;
$actionIconBorderRadius:50% !default;

//input field (e.g. inputtext, spinner, inputmask)
$inputPadding:.5rem .5rem !default;
$inputTextFontSize:1rem !default;

$inputBg:$white !default;
$inputTextColor:$black100 !default;
$inputIconColor:$primaryColorAlt !default;
$inputBorder:1px solid $white300 !default;
$inputHoverBorderColor:$primaryColorAlt !default;
$inputFocusBorderColor:$primaryColorAlt !default;
$inputErrorBorderColor:$errorColor !default;
$inputPlaceholderTextColor:$black100 !default;
$inputFilledBg:$white100 !default;
$inputFilledHoverBg:$inputFilledBg !default;
$inputFilledFocusBg:$white !default;

//input groups
$inputGroupBg:$white !default;
$inputGroupTextColor:$primaryColorAlt !default;
$inputGroupAddOnMinWidth:2.357rem !default;

//input lists (e.g. dropdown, autocomplete, multiselect, orderlist)
$inputListBg:$white !default;
$inputListTextColor:$black100 !default;
$inputListBorder:$inputBorder !default;
$inputListPadding:.5rem 0 !default;
$inputListItemPadding:.5rem 1rem !default;
$inputListItemBg:transparent !default;
$inputListItemTextColor:$black100 !default;
$inputListItemHoverBg:transparent !default;
$inputListItemTextHoverColor:$black100 !default;
$inputListItemBorder:0 none !default;
$inputListItemBorderRadius:0 !default;
$inputListItemMargin:0 !default;
$inputListItemFocusShadow:inset 0 0 0 0.15rem $focusOutlineColor !default;
$inputListHeaderPadding:.5rem 1rem !default;
$inputListHeaderMargin:0 !default;
$inputListHeaderBg:$white100 !default;
$inputListHeaderTextColor:$black100 !default;
$inputListHeaderBorder:0 none !default;

//inputs with overlays (e.g. autocomplete, dropdown, multiselect)
$inputOverlayBg:$inputListBg !default;
$inputOverlayHeaderBg:$inputListHeaderBg !default;
$inputOverlayBorder:0 none !default;                  
$inputOverlayShadow:0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12) !default;

//password
$passwordMeterBg:$white200 !default;
$passwordWeakBg:$dangerColor !default;
$passwordMediumBg:$warningColor !default;
$passwordStrongBg:$successColor !default;

//button
$buttonPadding:.5rem 1rem !default;
$buttonIconOnlyWidth:2.357rem !default;
$buttonIconOnlyPadding:.5rem 0 !default;
$buttonBg:$primaryColor !default;
$buttonTextColor:$primaryTextColor !default;
$buttonBorder:2px solid $primaryColor !default;
$buttonHoverBg:$primaryColorAlt !default;
$buttonTextHoverColor:$primaryTextColor !default;
$buttonHoverBorderColor:$primaryDarkColor !default;
$buttonActiveBg:$primaryDarkerColor !default;
$buttonTextActiveColor:$primaryTextColor !default;
$buttonActiveBorderColor:$primaryDarkerColor !default;
$raisedButtonShadow:0 4px 4px 0px rgba(0,0,0,.2), 0 12px 10px 0 rgba(0,0,0,.06) !default;
$roundedButtonBorderRadius:2rem !default;

$textButtonHoverBgOpacity:.12 !default;
$textButtonActiveBgOpacity:.24 !default;
$outlinedButtonBorder:2px solid !default;
$plainButtonTextColor:$textSecondaryColor !default;
$plainButtonHoverBgColor:$white !default;
$plainButtonActiveBgColor:$white200 !default;

$secondaryButtonBg:$black100 !default;
$secondaryButtonTextColor:#ffffff !default;
$secondaryButtonBorder:2px solid $black100 !default;
$secondaryButtonHoverBg:$black50 !default;
$secondaryButtonTextHoverColor:#ffffff !default;
$secondaryButtonHoverBorderColor:$black100 !default;
$secondaryButtonActiveBg:$black400 !default;
$secondaryButtonTextActiveColor:#ffffff !default;
$secondaryButtonActiveBorderColor:$black400 !default;
$secondaryButtonFocusShadow:0 0 0 0.2rem scale-color($secondaryButtonBg, $lightness: 60%) !default;

$infoButtonBg:$infoColor !default;
$infoButtonTextColor:#ffffff !default;
$infoButtonBorder:2px solid $infoButtonBg !default;
$infoButtonHoverBg:$infoLightColor !default;
$infoButtonTextHoverColor:$infoButtonTextColor !default;
$infoButtonHoverBorderColor:$infoColor !default;
$infoButtonActiveBg:scale-color($infoButtonBg, $lightness: -20%) !default;
$infoButtonTextActiveColor:$infoButtonTextColor !default;
$infoButtonActiveBorderColor:scale-color($infoButtonBg, $lightness: -20%) !default;
$infoButtonFocusShadow:0 0 0 0.2rem scale-color($infoButtonBg, $lightness: 60%) !default;

$successButtonBg:$successColor !default;
$successButtonTextColor:#ffffff !default;
$successButtonBorder:2px solid $successButtonBg !default;
$successButtonHoverBg:$successLightColor !default;
$successButtonTextHoverColor:$successButtonTextColor !default;
$successButtonHoverBorderColor:#7FA366 !default;
$successButtonActiveBg:scale-color($successButtonBg, $lightness: -20%) !default;
$successButtonTextActiveColor:$successButtonTextColor !default;
$successButtonActiveBorderColor:scale-color($successButtonBg, $lightness: -20%) !default;
$successButtonFocusShadow:0 0 0 0.2rem scale-color($successButtonBg, $lightness: 60%) !default;

$warningButtonBg:$warningColor !default;
$warningButtonTextColor:#ffffff !default;
$warningButtonBorder:2px solid $warningButtonBg !default;
$warningButtonHoverBg:$warningLightColor !default;
$warningButtonTextHoverColor:$warningButtonTextColor !default;
$warningButtonHoverBorderColor:$warningColor !default;
$warningButtonActiveBg:scale-color($warningButtonBg, $lightness: -20%) !default;
$warningButtonTextActiveColor:$warningButtonTextColor !default;
$warningButtonActiveBorderColor:scale-color($warningButtonBg, $lightness: -20%) !default;
$warningButtonFocusShadow:0 0 0 0.2rem scale-color($warningButtonBg, $lightness: 60%) !default;

$helpButtonBg:$helpColor !default;
$helpButtonTextColor:#ffffff !default;
$helpButtonBorder:2px solid $helpButtonBg !default;
$helpButtonHoverBg:$helpLightColor !default;
$helpButtonTextHoverColor:$helpButtonTextColor !default;
$helpButtonHoverBorderColor:$helpColor !default;
$helpButtonActiveBg:scale-color($helpButtonBg, $lightness: -20%) !default;
$helpButtonTextActiveColor:$helpButtonTextColor !default;
$helpButtonActiveBorderColor:scale-color($helpButtonBg, $lightness: -20%) !default;
$helpButtonFocusShadow:0 0 0 0.2rem scale-color($helpButtonBg, $lightness: 60%) !default;

$dangerButtonBg:$dangerColor !default;
$dangerButtonTextColor:#ffffff !default;
$dangerButtonBorder:2px solid $dangerButtonBg !default;
$dangerButtonHoverBg:$dangerLightColor !default;
$dangerButtonTextHoverColor:$dangerButtonTextColor !default;
$dangerButtonHoverBorderColor:$dangerColor !default;
$dangerButtonActiveBg:scale-color($dangerButtonBg, $lightness: -20%) !default;
$dangerButtonTextActiveColor:$dangerButtonTextColor !default;
$dangerButtonActiveBorderColor:scale-color($dangerButtonBg, $lightness: -20%) !default;
$dangerButtonFocusShadow:0 0 0 0.2rem scale-color($dangerButtonBg, $lightness: 60%) !default;

$linkButtonColor:$primaryDarkerColor !default;
$linkButtonHoverColor:$primaryDarkerColor !default;
$linkButtonTextHoverDecoration:underline !default;
$linkButtonFocusShadow:0 0 0 0.2rem $focusOutlineColor !default;

//checkbox
$checkboxWidth:20px !default;
$checkboxHeight:20px !default;
$checkboxBorder:2px solid $white300 !default;
$checkboxIconFontSize:14px !default;
$checkboxActiveBorderColor:$primaryColor !default;
$checkboxActiveBg:$primaryColor !default;
$checkboxIconActiveColor:$primaryTextColor !default;
$checkboxActiveHoverBg:$primaryColorAlt !default;
$checkboxIconActiveHoverColor:$primaryTextColor !default;
$checkboxActiveHoverBorderColor:$primaryDarkerColor !default;

//radiobutton
$radiobuttonWidth:20px !default;
$radiobuttonHeight:20px !default;
$radiobuttonBorder:2px solid $white300 !default;
$radiobuttonIconSize:10px !default;
$radiobuttonActiveBorderColor:$primaryColor !default;
$radiobuttonActiveBg:$primaryColor !default;
$radiobuttonIconActiveColor:$primaryTextColor !default;
$radiobuttonActiveHoverBg:$primaryColorAlt !default;
$radiobuttonIconActiveHoverColor:$primaryTextColor !default;
$radiobuttonActiveHoverBorderColor:$primaryColor !default;

//colorpicker
$colorPickerPreviewWidth:2rem !default;
$colorPickerPreviewHeight:2rem !default;
$colorPickerBg:#323232 !default;
$colorPickerBorder:1px solid #191919 !default;
$colorPickerHandleColor:$white !default;

//togglebutton
$toggleButtonBg:$inputBg !default;
$toggleButtonBorder:2px solid $white300 !default;
$toggleButtonTextColor:$black100 !default;
$toggleButtonIconColor:$primaryColorAlt !default;
$toggleButtonHoverBg:$white100 !default;
$toggleButtonHoverBorderColor:$primaryColorAlt !default;
$toggleButtonTextHoverColor:$black100 !default;
$toggleButtonIconHoverColor:$primaryColorAlt !default;
$toggleButtonActiveBg:$primaryColor !default;
$toggleButtonActiveBorderColor:$primaryColor !default;
$toggleButtonTextActiveColor:$primaryTextColor !default;
$toggleButtonIconActiveColor:$primaryTextColor !default;
$toggleButtonActiveHoverBg:$primaryColorAlt !default;
$toggleButtonActiveHoverBorderColor:$primaryColor !default;
$toggleButtonTextActiveHoverColor:$primaryTextColor !default;
$toggleButtonIconActiveHoverColor:$primaryTextColor !default;

//inplace
$inplacePadding:$inputPadding !default;
$inplaceHoverBg:$white !default;
$inplaceTextHoverColor:$black100 !default;

//rating
$ratingIconFontSize:1.143rem !default;
$ratingCancelIconColor:$dangerColor !default;
$ratingCancelIconHoverColor:$dangerLightColor !default;
$ratingStarIconOffColor:$black100 !default;
$ratingStarIconOnColor:$primaryColor !default;
$ratingStarIconHoverColor:$primaryColor !default;

//slider
$sliderBg:$white200 !default;
$sliderBorder:0 none !default;
$sliderHorizontalHeight:.286rem !default;
$sliderVerticalWidth:0.286rem !default;
$sliderHandleWidth:1.143rem !default;
$sliderHandleHeight:1.143rem !default;
$sliderHandleBg:$white !default;
$sliderHandleBorder:2px solid $primaryColor !default;
$sliderHandleBorderRadius:50% !default;
$sliderHandleHoverBorderColor:$primaryColor !default;
$sliderHandleHoverBg:$primaryColor !default;
$sliderRangeBg:$primaryColor !default;

//calendar
$calendarTableMargin:.5rem 0 !default;
$calendarPadding:.5rem !default;
$calendarBg:$white !default;
$calendarInlineBg:$calendarBg !default;
$calendarTextColor:$black100 !default;
$calendarBorder:$inputListBorder !default;
$calendarOverlayBorder:$inputOverlayBorder !default;

$calendarHeaderPadding:.5rem !default;
$calendarHeaderBg:$white !default;
$calendarInlineHeaderBg:$calendarBg !default;
$calendarHeaderBorder:1px solid $white200 !default;
$calendarHeaderTextColor:$black100 !default;
$calendarHeaderFontWeight:600 !default;
$calendarHeaderCellPadding:.5rem !default;
$calendarMonthYearHeaderHoverTextColor: $primaryColor !default;

$calendarCellDatePadding:.5rem !default;
$calendarCellDateWidth:2.5rem !default;
$calendarCellDateHeight:2.5rem !default;
$calendarCellDateBorderRadius:4px !default;
$calendarCellDateBorder:1px solid transparent !default;
$calendarCellDateHoverBg:$white300 !default;
$calendarCellDateTodayBg:$yellowColor !default;
$calendarCellDateTodayBorderColor:transparent !default;
$calendarCellDateTodayTextColor:$black400 !default;

$calendarButtonBarPadding:1rem 0 !default;
$calendarTimePickerPadding:.5rem !default;
$calendarTimePickerElementPadding:0 .5rem !default;
$calendarTimePickerTimeFontSize:1.25rem !default;

$calendarBreakpoint:769px !default;
$calendarCellDatePaddingSM:0 !default;

//input switch
$inputSwitchWidth:3rem !default;
$inputSwitchHeight:1.75rem !default;
$inputSwitchBorderRadius:30px !default;
$inputSwitchHandleWidth:1.250rem !default;
$inputSwitchHandleHeight:1.250rem !default;
$inputSwitchHandleBorderRadius:50% !default;
$inputSwitchSliderPadding:.25rem !default;
$inputSwitchSliderOffBg:$white300 !default;
$inputSwitchHandleOffBg:$primaryColor !default;
$inputSwitchSliderOffHoverBg:$white100 !default;
$inputSwitchSliderOnBg:$primaryColor !default;
$inputSwitchSliderOnHoverBg:$primaryColorAlt !default;
$inputSwitchHandleOnBg:$white !default;

//panel
$panelHeaderBorderColor: $white200 !default;
$panelHeaderBorder:1px solid $white200 !default;
$panelHeaderBg:$white !default;
$panelHeaderTextColor:$black100 !default;
$panelHeaderFontWeight:600 !default;
$panelHeaderPadding:1rem !default;
$panelToggleableHeaderPadding:.5rem 1rem !default;

$panelHeaderHoverBg:$white !default;
$panelHeaderHoverBorderColor:$white200 !default;
$panelHeaderTextHoverColor:$black100 !default;

$panelContentBorderColor: $white200 !default;
$panelContentBorder:1px solid $white200 !default;
$panelContentBg:$white !default;
$panelContentEvenRowBg:scale-color($white100, $lightness: 50%) !default;
$panelContentTextColor:$black100 !default;
$panelContentPadding:1rem !default;

$panelFooterBorder:1px solid $white200 !default;
$panelFooterBg:$white !default;
$panelFooterTextColor:$black100 !default;
$panelFooterPadding:0.5rem 1rem !default;

//accordion
$accordionSpacing:0 !default;
$accordionHeaderBorder:$panelHeaderBorder !default;
$accordionHeaderBg:$panelHeaderBg !default;
$accordionHeaderTextColor:$panelHeaderTextColor !default;
$accordionHeaderFontWeight:$panelHeaderFontWeight !default;
$accordionHeaderPadding:$panelHeaderPadding !default;

$accordionHeaderHoverBg:$white !default;
$accordionHeaderHoverBorderColor:$white200 !default;
$accordionHeaderTextHoverColor:$black100 !default;

$accordionHeaderActiveBg:$panelHeaderBg !default;
$accordionHeaderActiveBorderColor:$white200 !default;
$accordionHeaderTextActiveColor:$black100 !default;

$accordionHeaderActiveHoverBg:$white !default;
$accordionHeaderActiveHoverBorderColor:$white200 !default;
$accordionHeaderTextActiveHoverColor:$black100 !default;

$accordionContentBorder:$panelContentBorder !default;
$accordionContentBg:$panelContentBg !default;
$accordionContentTextColor:$panelContentTextColor !default;
$accordionContentPadding:$panelContentPadding !default;

//tabview
$tabviewNavBorder:2px solid $white200 !default;
$tabviewNavBorderWidth:0 0 2px 0 !default;
$tabviewNavBg:$white !default;

$tabviewHeaderSpacing:0 !default;
$tabviewHeaderBorder:solid $white200 !default;
$tabviewHeaderBorderWidth:0 0 2px 0 !default;
$tabviewHeaderBorderColor:transparent transparent $white200 transparent !default;
$tabviewHeaderBg:$white !default;
$tabviewHeaderTextColor:$black100 !default;
$tabviewHeaderFontWeight:$panelHeaderFontWeight !default;
$tabviewHeaderPadding:$panelHeaderPadding !default;
$tabviewHeaderMargin:0 0 -2px 0 !default;

$tabviewHeaderHoverBg:$white !default;
$tabviewHeaderHoverBorderColor:$primaryColorAlt !default;
$tabviewHeaderTextHoverColor:$black100 !default;

$tabviewHeaderActiveBg:$white !default;
$tabviewHeaderActiveBorderColor:$primaryColor !default;
$tabviewHeaderTextActiveColor:$black100 !default;

$tabviewContentBorder:0 none !default;
$tabviewContentBg:$panelContentBg !default;
$tabviewContentTextColor:$panelContentTextColor !default;
$tabviewContentPadding:$panelContentPadding !default;

//upload
$fileUploadProgressBarHeight:.25rem !default;
$fileUploadContentPadding:2rem 1rem !default;

//scrollpanel
$scrollPanelTrackBorder:0 none !default;
$scrollPanelTrackBg:$white100 !default;

//card
$cardBodyPadding:1rem !default;
$cardTitleFontSize:1.5rem !default;
$cardTitleFontWeight:700 !default;
$cardSubTitleFontWeight:400 !default;
$cardSubTitleColor:$primaryColorAlt !default;
$cardContentPadding:1rem 0 !default;
$cardFooterPadding:1rem 0 0 0 !default;
$cardShadow:0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12) !default;

//editor
$editorToolbarBg:$panelHeaderBg !default;
$editorToolbarBorder:$panelHeaderBorder !default;
$editorToolbarPadding:$panelHeaderPadding !default;
$editorToolbarIconColor:$textSecondaryColor !default;
$editorToolbarIconHoverColor:$textColor !default;
$editorIconActiveColor:$primaryColor !default;
$editorContentBorder:$panelContentBorder !default;
$editorContentBg:$panelContentBg !default;

//paginator
$paginatorBg:$white !default;
$paginatorTextColor:$black100 !default;
$paginatorBorder:solid $white !default;
$paginatorBorderWidth:0 !default;
$paginatorPadding:.5rem 1rem !default;
$paginatorElementWidth:$buttonIconOnlyWidth !default;
$paginatorElementHeight:$buttonIconOnlyWidth !default;
$paginatorElementBg:transparent !default;
$paginatorElementBorder:0 none !default;
$paginatorElementIconColor:$black100 !default;
$paginatorElementHoverBg:$white100 !default;
$paginatorElementHoverBorderColor:$primaryColor !default;
$paginatorElementIconHoverColor:$black100 !default;
$paginatorElementBorderRadius:$borderRadius !default;
$paginatorElementMargin:.143rem !default;
$paginatorElementPadding:0 !default;

//table
$tableHeaderBorder:1px solid $white !default;
$tableHeaderBorderWidth:1px 0 1px 0 !default;
$tableHeaderBg:$primaryColor !default;
$tableHeaderTextColor:$white !default;
$tableHeaderFontWeight:600 !default;
$tableHeaderPadding:1rem 1rem !default;

$tableHeaderCellPadding:1rem 1rem !default;
$tableHeaderCellBg:$white100 !default;
$tableHeaderCellTextColor:$black100 !default;
$tableHeaderCellFontWeight:600 !default;
$tableHeaderCellBorder:1px solid $white !default;
$tableHeaderCellBorderWidth:0 0 1px 0 !default;
$tableHeaderCellHoverBg:$white !default;
$tableHeaderCellTextHoverColor:$black100 !default;
$tableHeaderCellIconColor:$primaryColorAlt !default;
$tableHeaderCellIconHoverColor:$primaryColorAlt !default;
$tableHeaderCellHighlightBg:$white100 !default;
$tableHeaderCellHighlightTextColor:$black100 !default;
$tableHeaderCellHighlightHoverBg:$white !default;
$tableHeaderCellHighlightTextHoverColor:$primaryColor !default;
$tableSortableColumnBadgeSize:1.143rem !default;

$tableBodyRowBg:$white !default;
$tableBodyRowTextColor:$black100 !default;
$tableBodyRowEvenBg:scale-color($white100, $lightness: 50%) !default;
$tableBodyRowHoverBg:$white !default;
$tableBodyRowTextHoverColor:$black100 !default;
$tableBodyCellBorder:1px solid $white100 !default;
$tableBodyCellBorderWidth:0 0 1px 0 !default;
$tableBodyCellPadding:1rem 1rem !default;

$tableFooterCellPadding:1rem 1rem !default;
$tableFooterCellBg:$white100 !default;
$tableFooterCellTextColor:$black100 !default;
$tableFooterCellFontWeight:600 !default;
$tableFooterCellBorder:1px solid $white !default;
$tableFooterCellBorderWidth:0 0 1px 0 !default;
$tableResizerHelperBg:$primaryColor !default;
$tableDragHelperBg: rgba($primaryColor, .16) !default;

$tableFooterBorder:1px solid $white !default;
$tableFooterBorderWidth:0 0 1px 0 !default;
$tableFooterBg:$white100 !default;
$tableFooterTextColor:$black100 !default;
$tableFooterFontWeight:600 !default;
$tableFooterPadding:1rem 1rem !default;

$tableCellContentAlignment:left !default;
$tableTopPaginatorBorderWidth:0 0 1px 0 !default;
$tableBottomPaginatorBorderWidth:0 0 1px 0 !default;

$tableScaleSM:0.5 !default;
$tableScaleLG:1.25 !default;

//dataview
$dataViewContentPadding:0 !default;
$dataViewContentBorder:0 none !default;
$dataViewListItemBorder:solid $white !default;
$dataViewListItemBorderWidth:0 0 1px 0 !default;

//tree
$treeContainerPadding:0.286rem !default;
$treeNodePadding:0.143rem !default;
$treeNodeContentPadding:0 !default;
$treeNodeChildrenPadding:0 0 0 1rem !default;
$treeNodeIconColor:$primaryColorAlt !default;

//timeline
$timelineVerticalEventContentPadding:0 1rem !default;
$timelineHorizontalEventContentPadding:1rem 0 !default;
$timelineEventMarkerWidth:1rem !default;
$timelineEventMarkerHeight:1rem !default;
$timelineEventMarkerBorderRadius:50% !default;
$timelineEventMarkerBorder:0 none !default;
$timelineEventMarkerBackground:$primaryColor !default;
$timelineEventConnectorSize:2px !default;
$timelineEventColor:$white200 !default;

//org chart
$organizationChartConnectorColor:$white200 !default;

//message
$messageMargin:1rem 0 !default;
$messagePadding:1rem 1.5rem !default;
$messageBorderWidth:0 0 0 6px !default;
$messageIconFontSize:1.5rem !default;
$messageTextFontSize:1rem !default;
$messageTextFontWeight:500 !default;

//inline message
$inlineMessagePadding:$inputPadding !default;
$inlineMessageMargin:0 !default;
$inlineMessageIconFontSize:1rem !default;
$inlineMessageTextFontSize:1rem !default;
$inlineMessageBorderWidth:0px !default;

//toast
$toastIconFontSize:2rem !default;
$toastMessageTextMargin:0 0 0 1rem !default;
$toastMargin:0 0 1rem 0 !default;
$toastPadding:1rem !default;
$toastBorderWidth:0 0 0 6px !default;
$toastShadow:0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12) !default;
$toastOpacity:.9 !default;
$toastTitleFontWeight:700 !default;
$toastDetailMargin:$inlineSpacing 0 0 0 !default;

//severities
$infoMessageBg:scale-color($infoLightColor, $lightness: +40%) !default;
$infoMessageBorder:solid scale-color($infoColor, $lightness: -50%) !default;
$infoMessageTextColor:scale-color($black100, $lightness: -50%) !default;
$infoMessageIconColor:scale-color($infoColor, $lightness: -75%) !default;
$successMessageBg:scale-color($successLightColor, $lightness: +40%) !default;
$successMessageBorder:solid scale-color($successColor, $lightness: -50%) !default;
$successMessageTextColor:scale-color($successColor, $lightness: -75%) !default;
$successMessageIconColor:scale-color($successColor, $lightness: -75%) !default;
$warningMessageBg:scale-color($warningLightColor, $lightness: +50%) !default;
$warningMessageBorder:solid scale-color($warningColor, $lightness: -50%) !default;
$warningMessageTextColor:scale-color($warningColor, $lightness: -75%) !default;
$warningMessageIconColor:scale-color($warningColor, $lightness: -75%) !default;
$errorMessageBg:scale-color($dangerLightColor, $lightness: +40%) !default;
$errorMessageBorder:solid scale-color($dangerColor, $lightness: -50%) !default;
$errorMessageTextColor:scale-color($dangerColor, $lightness: -75%) !default;
$errorMessageIconColor:scale-color($dangerColor, $lightness: -75%) !default;

//overlays
$overlayContentBorder:0 none !default;
$overlayContentBg:$panelContentBg !default;
$overlayContainerShadow:0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0,0,0,.12) !default;

//dialog
$dialogHeaderBg:$white !default;
$dialogHeaderBorder:0 none !default;
$dialogHeaderTextColor:$black100 !default;
$dialogHeaderFontWeight:600 !default;
$dialogHeaderFontSize:1.25rem !default;
$dialogHeaderPadding:1.5rem !default;
$dialogContentPadding:0 1.5rem 2rem 1.5rem !default;
$dialogFooterBorder:0 none !default;
$dialogFooterPadding:0 1.5rem 1.5rem 1.5rem !default;

//confirmpopup
$confirmPopupContentPadding:$panelContentPadding !default;
$confirmPopupFooterPadding:0 1rem 1rem 1rem !default;

//tooltip
$tooltipBg:$black100 !default;
$tooltipTextColor:$white !default;
$tooltipPadding:$inputPadding !default;

//steps
$stepsItemBg:$white !default;
$stepsItemBorder:1px solid $white !default;
$stepsItemTextColor:$primaryColorAlt !default;
$stepsItemNumberWidth:2rem !default;
$stepsItemNumberHeight:2rem !default;
$stepsItemNumberFontSize:1.143rem !default;
$stepsItemNumberColor:$black100 !default;
$stepsItemNumberBorderRadius:50% !default;
$stepsItemActiveFontWeight:600 !default;

//progressbar
$progressBarHeight:1.5rem !default;
$progressBarBorder:0 none !default;
$progressBarBg:$white200 !default;
$progressBarValueBg:$primaryColor !default;
$progressBarValueTextColor:$primaryTextColor !default;

//menu (e.g. menu, menubar, tieredmenu)
$menuWidth:12.5rem !default;
$menuBg:$white !default;
$menuBorder:1px solid $white200 !default;
$menuTextColor:$black100 !default;
$menuitemPadding:.75rem 1rem !default;
$menuitemBorderRadius:0 !default;
$menuitemTextColor:$black100 !default;
$menuitemIconColor:$primaryColorAlt !default;
$menuitemTextHoverColor:$black100 !default;
$menuitemIconHoverColor:$primaryColorAlt !default;
$menuitemHoverBg:$white !default;
$menuitemTextActiveColor:$black100 !default;
$menuitemIconActiveColor:$primaryColorAlt !default;
$menuitemActiveBg:$white !default;
$menuitemSubmenuIconFontSize:.875rem !default;
$submenuHeaderMargin:0 !default;
$submenuHeaderPadding:.75rem 1rem !default;
$submenuHeaderBg:$white !default;
$submenuHeaderTextColor:$black100 !default;
$submenuHeaderBorderRadius:0 !default;
$submenuHeaderFontWeight:600 !default;
$overlayMenuBg:$menuBg !default;
$overlayMenuBorder:0 none !default;
$overlayMenuShadow:0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12) !default;
$verticalMenuPadding:.25rem 0 !default;
$menuSeparatorMargin:.25rem 0 !default;

$breadcrumbPadding:1rem !default;
$breadcrumbBg:$menuBg !default;
$breadcrumbBorder:$menuBorder !default;
$breadcrumbItemTextColor:$menuitemTextColor !default;
$breadcrumbItemIconColor:$menuitemIconColor !default;
$breadcrumbLastItemTextColor:$menuitemTextColor !default;
$breadcrumbLastItemIconColor:$menuitemIconColor !default;
$breadcrumbSeparatorColor:$menuitemTextColor !default;

$horizontalMenuPadding:.5rem !default;
$horizontalMenuBg:$white100 !default;
$horizontalMenuBorder:$menuBorder !default;
$horizontalMenuTextColor:$menuTextColor !default;
$horizontalMenuRootMenuitemPadding:$menuitemPadding !default;
$horizontalMenuRootMenuitemBorderRadius:$borderRadius !default;
$horizontalMenuRootMenuitemTextColor:$menuitemTextColor !default;
$horizontalMenuRootMenuitemIconColor:$menuitemIconColor !default;
$horizontalMenuRootMenuitemTextHoverColor:$menuitemTextHoverColor !default;
$horizontalMenuRootMenuitemIconHoverColor:$menuitemIconHoverColor !default;
$horizontalMenuRootMenuitemHoverBg:$menuitemHoverBg !default;
$horizontalMenuRootMenuitemTextActiveColor:$menuitemTextActiveColor !default;
$horizontalMenuRootMenuitemIconActiveColor:$menuitemIconActiveColor !default;
$horizontalMenuRootMenuitemActiveBg:$menuitemActiveBg !default;

//badge and tag
$badgeBg:$primaryColor !default;
$badgeTextColor:$primaryTextColor !default;
$badgeMinWidth:1.5rem !default;
$badgeHeight:1.5rem !default;
$badgeFontWeight:700 !default;
$badgeFontSize:.75rem !default;

$tagPadding:.25rem .4rem !default;

//carousel
$carouselIndicatorsPadding:1rem !default;
$carouselIndicatorBg:$white200 !default;
$carouselIndicatorHoverBg:$primaryColorAlt !default;
$carouselIndicatorBorderRadius:0 !default;
$carouselIndicatorWidth:2rem !default;
$carouselIndicatorHeight:.5rem !default;

//galleria
$galleriaMaskBg:rgba(0,0,0,0.9) !default;
$galleriaCloseIconMargin:.5rem !default;
$galleriaCloseIconFontSize:2rem !default;
$galleriaCloseIconBg:transparent !default;
$galleriaCloseIconColor:$white100 !default;
$galleriaCloseIconHoverBg:rgba(255,255,255,0.1) !default;
$galleriaCloseIconHoverColor:$white100 !default;
$galleriaCloseIconWidth:4rem !default;
$galleriaCloseIconHeight:4rem !default;
$galleriaCloseIconBorderRadius:50% !default;

$galleriaItemNavigatorBg:transparent !default;
$galleriaItemNavigatorColor:$white100 !default;
$galleriaItemNavigatorMargin:0 .5rem !default;
$galleriaItemNavigatorFontSize:2rem !default;
$galleriaItemNavigatorHoverBg:rgba(255,255,255,0.1) !default;
$galleriaItemNavigatorHoverColor:$white100 !default;
$galleriaItemNavigatorWidth:4rem !default;
$galleriaItemNavigatorHeight:4rem !default;
$galleriaItemNavigatorBorderRadius:$borderRadius !default;

$galleriaCaptionBg:rgba(0,0,0,.5) !default;
$galleriaCaptionTextColor:$white100 !default;
$galleriaCaptionPadding:1rem !default;

$galleriaIndicatorsPadding:1rem !default;
$galleriaIndicatorBg:$white300 !default;
$galleriaIndicatorHoverBg:$primaryColorAlt !default;
$galleriaIndicatorBorderRadius:50% !default;
$galleriaIndicatorWidth:1rem !default;
$galleriaIndicatorHeight:1rem !default;
$galleriaIndicatorsBgOnItem:rgba(0,0,0,.5) !default;
$galleriaIndicatorBgOnItem:rgba(255,255,255,.4) !default;
$galleriaIndicatorHoverBgOnItem:rgba(255,255,255,.6) !default;

$galleriaThumbnailContainerBg:rgba(0,0,0,.9) !default;
$galleriaThumbnailContainerPadding:1rem .25rem !default;
$galleriaThumbnailNavigatorBg:transparent !default;
$galleriaThumbnailNavigatorColor:$white100 !default;
$galleriaThumbnailNavigatorHoverBg:rgba(255,255,255,0.1) !default;
$galleriaThumbnailNavigatorHoverColor:$white100 !default;
$galleriaThumbnailNavigatorBorderRadius:50% !default;
$galleriaThumbnailNavigatorWidth:2rem !default;
$galleriaThumbnailNavigatorHeight:2rem !default;

//divider
$dividerHorizontalMargin:1rem 0 !default;
$dividerHorizontalPadding:0 1rem !default;
$dividerVerticalMargin:0 1rem !default;
$dividerVerticalPadding:1rem 0 !default;
$dividerSize:1px !default;
$dividerColor:$white200 !default;

//avatar
$avatarBg:$white200 !default;
$avatarTextColor:$textColor !default;

//avatar
$chipBg:$white200 !default;
$chipTextColor:$textColor !default;
$chipBorderRadius: 16px !default;

//scrollTop
$scrollTopBg:rgba(0,0,0,0.7) !default;
$scrollTopHoverBg:rgba(0,0,0,0.8) !default;
$scrollTopWidth:3rem !default;
$scrollTopHeight:3rem !default;
$scrollTopBorderRadius:50% !default;
$scrollTopFontSize:1.5rem !default;
$scrollTopTextColor:$white100 !default;

//skeleton
$skeletonBg:$white200 !default;
$skeletonAnimationBg:rgba(255,255,255,0.4) !default;

//splitter
$splitterGutterBg:$white100 !default;
$splitterGutterHandleBg:#c2c7d1 !default;

//speeddial
$speedDialButtonWidth: 4rem !default;
$speedDialButtonHeight: 4rem !default;
$speedDialButtonIconFontSize: 2rem !default;
$speedDialActionWidth: 3rem !default;
$speedDialActionHeight: 3rem !default;
$speedDialActionBg: $black100 !default;
$speedDialActionHoverBg: $black300 !default;
$speedDialActionTextColor: #fff !default;
$speedDialActionTextHoverColor: #fff !default;

//dock
$dockActionWidth: 4rem !default;
$dockActionHeight: 4rem !default;
$dockItemPadding: .5rem !default;
$dockCurrentItemMargin: 1.5rem !default;
$dockFirstItemsMargin: 1.3rem !default;
$dockSecondItemsMargin: 0.9rem !default;
$dockBg: rgba(255,255,255,.1) !default;
$dockBorder: 1px solid rgba(255,255,255,0.2) !default;
$dockPadding: .5rem .5rem !default;
$dockBorderRadius: .5rem !default;

//image
$imageMaskBg:rgba(0,0,0,0.9) !default;
$imagePreviewToolbarPadding:1rem !default;
$imagePreviewIndicatorColor:#f8f9fa !default;
$imagePreviewIndicatorBg:rgba(0,0,0,0.5) !default;
$imagePreviewActionIconBg:transparent !default;
$imagePreviewActionIconColor:#f8f9fa !default;
$imagePreviewActionIconHoverBg:rgba(255,255,255,0.1) !default;
$imagePreviewActionIconHoverColor:#f8f9fa !default;
$imagePreviewActionIconWidth:3rem !default;
$imagePreviewActionIconHeight:3rem !default;
$imagePreviewActionIconFontSize:1.5rem !default;
$imagePreviewActionIconBorderRadius:50% !default;

:root {
    font-family:'Inter', sans-serif;
    --font-family:'Inter', sans-serif;
    --surface-a:#{$white};
    --surface-b:#{$white100};
    --surface-c:#{$white300};
    --surface-d:#{$white200};
    --surface-e:#{$white};
    --surface-f:#{$white};
    --text-color:#{$black100};
    --text-color-secondary:#{$primaryColorAlt};
    --primary-color:#{$primaryColor};
    --primary-color-text:#{$primaryTextColor};
    --surface-0: #ffffff;
    --surface-50: #edeef0;
    --surface-100: #dbdde1;
    --surface-200: #b7bbc3;
    --surface-300: #949aa6;
    --surface-400: #707888;
    --surface-500: #4c566a;
    --surface-600: #3d4555;
    --surface-700: #2e3440;
    --surface-800: #1e222a;
    --surface-900: #0f1115;
    --gray-50: #edeef0;
    --gray-100: #dbdde1;
    --gray-200: #b7bbc3;
    --gray-300: #949aa6;
    --gray-400: #707888;
    --gray-500: #4c566a;
    --gray-600: #3d4555;
    --gray-700: #2e3440;
    --gray-800: #1e222a;
    --gray-900: #0f1115;
    --content-padding:#{$panelContentPadding};
    --inline-spacing:#{$inlineSpacing};
    --border-radius:#{$borderRadius};
    --surface-ground:#ECEFF4;
    --surface-section:#ffffff;
    --surface-card:#ffffff;
    --surface-overlay:#ffffff;
    --surface-border:#E5E9F0;
    --surface-hover:#D8DEE9;
    --focus-ring: #{$focusShadow};
    --maskbg: #{$maskBg};
    --highlight-bg: #{$highlightBg};
    --highlight-text-color: #{$highlightTextColor};
    color-scheme: light;
}