// Configuration for the font-face of the theme, defaults to the system font so left as blank
    @font-face {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        src: local('Inter'), local('Inter-Regular'),
            url('./fonts/Inter-Regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
            url('./fonts/Inter-Regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    }
    @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: local('Inter Medium'), local('Inter-Medium'),
            url('./fonts/Inter-Medium.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
            url('./fonts/Inter-Medium.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    }
    @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    src: local('Inter SemiBold'), local('Inter-SemiBold'),
            url('./fonts/Inter-SemiBold.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
            url('./fonts/Inter-SemiBold.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    }
    @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    src: local('Inter Bold'), local('Inter-Bold'),
            url('./fonts/Inter-Bold.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
            url('./fonts/Inter-Bold.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    }